import cookieCutter from 'cookie-cutter';
import { getLiveChannelType } from './liveUtil';
import { useRouter } from 'next/router';

export const getChannelXml = (channelId) => {
  let channelXml;

  switch (channelId) {
    case 'S01':
      channelXml = 'SBS_500K';
      break;
    case 'S02':
      channelXml = 'SBSSPORTS_500K';
      break;
    case 'S03':
      channelXml = 'SBSPLUS_500K';
      break;
    case 'S04':
      channelXml = 'SBSETV_500K';
      break;
    case 'S05':
      channelXml = 'SBSGOLF_500K';
      break;
    case 'S06':
      channelXml = 'SBSCNBC_500K';
      break;
    case 'S07':
      channelXml = 'POWER';
      break;
    case 'S08':
      channelXml = 'LOVE';
      break;
    case 'S09':
      channelXml = 'SBSMTV_500K';
      break;
    default:
      channelXml = '';
      break;
  }
  return channelXml;
};

export const isNull = (value) => {
  return (value === undefined || value === null);
};

export const isIOS = () => {
  if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
    return true;
  } else {
    return navigator.maxTouchPoints &&
      navigator.maxTouchPoints >= 2 && (/MacIntel/.test(navigator.userAgent) ||
        /Mac/.test(navigator.userAgent));
  }
};

export const isWebView = () => { // TODO childmild
  if (navigator.userAgent.indexOf('SBSApp') > -1) {
    return true;
  } else {
    const appFromType = getParamByName('app_from_type', '');
    // usergent 이슈가 있기 때문에 아래와 같이 처리
    if (appFromType == 'APP' || appFromType == 'AMN') {	// APP=플레이어엔드, AMN=온에어메인
      return true;
    } else {
      return false;
    }
  }
};

export const getParamByName = (name, url) => {
  if (!isNull(url)) {
    url = window.location.href;
  }
  name = name.replace(/[\[\]]/g, '\\$&');

  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const returnVal = regex.exec(url);
  if (!isNull(returnVal)) {
    return null;
  }
  if (!isNull(returnVal[2])) {
    return '';
  }
  return decodeURIComponent(returnVal[2].replace(/\+/g, ' '));
};

export const isAndroid = () => {
  if (/Android/.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
};

export const getAppleStoreLink = () => {
  return 'https://itunes.apple.com/kr/app/id1020136055';
};

export const getAppLink = (channelId, payType, channelMode) => {
  const LOGIN_JWT = cookieCutter.get('LOGIN_JWT');

  const channelType = getLiveChannelType(channelId);
  channelMode = (channelMode === undefined && channelType === '24tv') ? 'virtualchannel' : (channelMode ? channelMode.trim() : '');

  let url = '';
  let params = '';

  channelId = channelId.trim();
  const channelXml = getChannelXml(channelId).trim();
  payType = (payType === 'Y') ? 'CB' : 'FR';


  params = `?Channel=${channelXml}&channel=${channelId}
  &type=${payType}${LOGIN_JWT ? '&token=' + LOGIN_JWT : ''}&sbs_id=&sbs_val=&sbs_un=
  &flag=a4&flag2=&plink=AMN&mode=${channelMode}`; // virtualchannel?

  params = params.replace(/ /g, '');

  if (isAndroid()) {
    url = 'intent://onair' + params + '#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end';
  } else if (isIOS()) {
    url = 'sbsplayer://onair' + params + '&returnUrl=' + encodeURIComponent(window.location.href);
  } else {
    url = '/live/' + channelId;
  }

  return url;
};

// android 부분 파라미터 부분 intent 전에 파라미터 넣기 전에 처리되어있음
export const getAppLinkInBox = (channelId, payType, channelMode) => {
  const LOGIN_JWT = cookieCutter.get('LOGIN_JWT');

  const channelType = getLiveChannelType(channelId);
  channelMode = (channelMode === undefined && channelType === '24tv') ? 'virtualchannel' : (channelMode ? channelMode.trim() : '');

  let url = '';
  let params = '';

  // 카타르 월드컵 멀티오디오 예외처리


  channelId = channelId.trim();
  const channelXml = getChannelXml(channelId).trim();
  payType = (payType === 'Y') ? 'CB' : 'FR';

  params = `?Channel=${channelXml}&channel=${channelId}
  &type=${payType}${LOGIN_JWT ? '&token=' + LOGIN_JWT : ''}&sbs_id=&sbs_val=&sbs_un=
  &flag=a4&flag2=&plink=AMN&mode=${channelMode}`; // virtualchannel?

  params = params.replace(/ /g, '');

  if (isAndroid()) {
    url = 'intent://onair' + params + '#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end';
  } else if (isIOS()) {
    url = 'sbsplayer://onair' + params + '&returnUrl=' + encodeURIComponent(window.location.href);
  } else {
    url = '/live/' + channelId;
  }

  return url;
};

export const getMediaAppLink = (mediaId, url, type) => {
  if (isAndroid() || isIOS()) {
    const LOGIN_JWT = cookieCutter.get('LOGIN_JWT');

    if (isAndroid()) {
      if (type === 'vod') {
        url = `intent://vod?cid=${mediaId}&type=VOD${LOGIN_JWT ? '&token=' + LOGIN_JWT : ''}&playmode=userclick&plink=OEP#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end`;
      } else {
        url = `intent://clip?cid=${mediaId}${LOGIN_JWT ? '&token=' + LOGIN_JWT : ''}&rscuse=05&plink=OEP#Intent;scheme=sbsplayer;package=kr.co.sbs.videoplayer;end`;
      }
    }else {
      if (type === 'vod') {
        url = `sbsplayer://vod?cid=${mediaId}&type=VOD${LOGIN_JWT ? '&token=' + LOGIN_JWT : ''}&playmode=userclick&plink=OEP`;
      } else {
        url = `sbsplayer://clip?cid=${mediaId}${LOGIN_JWT ? '&token=' + LOGIN_JWT : ''}&rscuse=05&plink=OEP`;
      }
    }
    
  } else {
    return url;
  }

  return url;
};

export const getClickLinkParams = (linkUrl = '', clickParams = '') => {
  try {
    const router = useRouter();
    const key = clickParams.split('=')[0];
    const value = clickParams.split('=')[1];
    if (linkUrl.indexOf('http') === -1) {
      router.query[key] = value;

      let parameter = Object.keys(router.query).map(function (x) {
        if (encodeURIComponent(x) !== 'channelID') {
          return encodeURIComponent(x) + '=' + encodeURIComponent(router.query[x]);
        }
      }).join('&');
      const queryArray = parameter.match(/=/g);
      if (queryArray && queryArray.length === 1) {
        parameter = parameter.replace('&', '');
      }
      linkUrl = `${linkUrl}?${parameter}`;
    } else {
      const httpUrl = new URL(linkUrl);
      httpUrl.searchParams.set(key, value);

      linkUrl = httpUrl.toString();
    }
  } catch (error) {
    console.error(error);
  }
  return linkUrl;
}
  ;
